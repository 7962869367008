
import { Component, Vue } from 'vue-property-decorator';

import { ValidationObserver } from 'vee-validate';

// Components
import Title from '@/components/common/Title.vue';
import OnboardLayout from '@/components/OnboardLayout.vue';
import { AuthService, UserService } from '@/services';
import { IUserNotificationSettings } from '@/types/user.types';
import { UserModule } from '@/store/modules';
import { OnboardingSteps } from '@/components/onboarding';

// Config Variables
import { ActiveTenant } from '../../../active-tenant';
import { tenants } from '../../../tenant-config';

@Component({
  components: {
    Title,
    ValidationObserver,
    OnboardLayout,
    OnboardingSteps
  }
})
export default class SetNotification extends Vue {
  visible = false;
  activeTenant = ActiveTenant;
  currentTenant = tenants[ActiveTenant];

  onboardLayoutMobileSetupTitle = this.currentTenant.onboardLayoutMobileSetupTitle;
  onboardLayoutMobileSetupTextPrimary = this.currentTenant.onboardLayoutMobileSetupTextPrimary;
  onboardLayoutMobileSetupTextSecondary = this.currentTenant.onboardLayoutMobileSetupTextSecondary;
  onboardLayoutMarketingText = this.currentTenant.onboardLayoutMarketingText;
  onboardLayoutMarketingTextSecondary = this.currentTenant.onboardLayoutMarketingTextSecondary;

  form = {
    mobilePhoneNumber: ''
  };
  notificationSettings: IUserNotificationSettings = {
    sms: false,
    transactionSms: false,
    transactionEmail: true,
    marketingSms: false,
    marketingEmail: false
  };

  async onSetPreferences(): Promise<void> {
    try {
      await UserService.updateProfile({
        notificationSettings: this.notificationSettings,
        // We cannot send an empty string or null to the API
        mobilePhoneNumber: this.form.mobilePhoneNumber || undefined
      });

      const user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (e: any) {
      this.$notifyError(this.$getError(e));
    }
    try {
      await AuthService.sendVerifyAccountEmail();
      this.$router.replace({
        path: '/account-verification'
      });
    } catch (e: any) {
      const message = this.$getError(e, 'Could not send an email with an account verification code');
      this.$notifyError(message, true);
    }
  }

  updateSwitchValue() {
    if (this.form.mobilePhoneNumber === '') {
      this.notificationSettings.marketingSms = false;
      this.notificationSettings.transactionSms = false;
    }
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }

  goNext(elem: any): void {
    const currentIndex = Array.from(elem.form.elements).indexOf(elem);
    elem.form.elements.item(currentIndex < elem.form.elements.length - 1 ? currentIndex + 1 : 0).focus();
  }

  get mobileFormated() {
    return Vue.filter('phone')(this.form.mobilePhoneNumber);
  }

  set mobileFormated(input: string) {
    const clean = input.replaceAll(' ', '');
    this.form.mobilePhoneNumber = clean.length < 11 ? clean : this.form.mobilePhoneNumber;
  }
}
